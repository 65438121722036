/* eslint-disable */

import request from './request';

//로그인
export const login = async formData => {
    return request('Admin/signIn', 'post', {}, formData);
};

export const tokenState = async formData => {
    return request('State/tokenState', 'post', {}, formData);
};

//회원목록
export const userList = async (formData, tokenValue) => {
    return request('User/getList', 'post', { Authorization: tokenValue }, formData);
}

//목회자 분류
export const getTypeList = async (tokenValue) => {
    return request('Pastor/getType', 'get', { Authorization: tokenValue });
}

//행정구역 조회 (시도)
export const getSidoList = async (tokenValue) => {
    return request('Juso/getSido', 'get', { Authorization: tokenValue });
}

//행정구역 조회 (시군구)
export const getSigunguList = async (formData, tokenValue) => {
    return request('Juso/getSigungu', 'post', { Authorization: tokenValue }, formData);
}

//행정구역 조회 (동)
export const getDongList = async (formData, tokenValue) => {
    return request('Juso/getDong', 'post', { Authorization: tokenValue }, formData);
}

//파일 업로드 (일반, 멀티)
export const uploadFileMultiple = async (formData, tokenValue) => {
    return request('File/uploadFileMultiple', 'post', { Authorization: tokenValue }, formData);
}

//파일 업로드 (일반, 싱글)
export const uploadFile = async (formData, tokenValue) => {
    return request('File/uploadFile', 'post', { Authorization: tokenValue }, formData);
}

//새 교회 등록
export const newChurch = async (formData, tokenValue) => {
    return request('Church/newChurch', 'post', { Authorization: tokenValue }, formData);
}

//푸쉬 발송
export const newPush = async (formData, tokenValue) => {
    return request('Push/newPush', 'post', { Authorization: tokenValue }, formData);
}

//푸쉬 기록
export const getPushList = async (formData, tokenValue) => {
    return request('Push/getList', 'post', { Authorization: tokenValue }, formData);
}

//교회 관리자 비밀번호 재설정
export const churchResetPassword = async (formData, tokenValue) => {
    return request('Church/resetPassword', 'post', { Authorization: tokenValue }, formData);
}

//교회 정회원/준회원 승인처리
export const setApproved = async (formData, tokenValue) => {
    return request('User/setApproved', 'post', { Authorization: tokenValue }, formData);
}

//교단목록
export const getGroupList = async (tokenValue) => {
    return request('Group/getList', 'get', { Authorization: tokenValue });
}

//교회 등록정보 조회
export const getChurchInfo = async (tokenValue) => {
    return request('Church/getDetail', 'get', { Authorization: tokenValue });
}

//교회 정보 수정
export const changeInfo = async (formData, tokenValue) => {
    return request('Church/changeInfo', 'post', { Authorization: tokenValue }, formData);
}

