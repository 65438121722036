/* eslint-disable */

import axios from 'axios';

const SERVER = "http://15.164.64.250:7002/api/v1";

// API request 모듈
const request = async (url, method, headers = {}, formData) => {
  const Address = SERVER + "/" + url;

  try {
    if (method === 'get') {
      const response = await axios[method](Address, {
        headers: {
          ...headers,
        },
      });
      return response;
    } else if (method === 'post') {
      let response = null;

      if (headers == {}) {
        response = await axios[method](Address, formData);
      } else {
        response = await axios[method](Address, formData, {
          headers: {
            ...headers,
          },
        });
      }

      return response;
    }
  } catch (error) {
    console.log('request function error', error);
    await Promise.reject(error);
  }
};
export default request;
