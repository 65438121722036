/* eslint-disable */

/**
 * External Dependencies
 */
import React, { Component } from 'react';

/**
 * Internal Dependencies
 */
import AsyncComponent from '../../../components/async-component';
import PageWrap from '../../../components/page-wrap';
import PageTitle from '../../../components/page-title';
import PageContent from '../../../components/page-content';

import Cookies from 'js-cookie';
import { tokenState } from '../../../api/api';

/**
 * Component
 */
class AccountList extends Component {

    render() {
        return (
            <PageWrap>
                <PageTitle
                    breadcrumbs={ {
                        '/': 'Home',
                        '#': '교인관리',
                        '/semi-user-list': '교인 목록(준)',
                    } }
                >
                    <h1>교인 목록(준)</h1>
                </PageTitle>
                <PageContent>
                    <AsyncComponent component={ () => import( './content' ) } />
                </PageContent>
            </PageWrap>
        );
    }
}

export default AccountList;
